// src/components/HowItWorks.js
import React from "react";
import { Container, Typography, Grid, Paper, Box } from "@mui/material";

const HowItWorks = () => {
  const steps = [
    {
      title: "SPARK",
      header: "Validate Your Idea",
      description: (
        <>
          Upload your script and watch our <strong>AI Director</strong> bring
          your narrative to life with stunning visuals
        </>
      ),
      number: 1,
    },
    {
      title: "SCULPT",
      header: "Visualise Instantly",
      description: (
        <>
          Fine-tune for <strong>consistent visuals</strong> and audio to perfectly capture the essence of your creative spark
        </>
      ),
      number: 2,
    },
    {
      title: "SIMULATE",
      header: "Generate Your Video",
      description:
        "Transform your visuals into an immersive video experience with captivating audio",
      number: 3,
    },
    {
      title: "SHARE",
      header: "Edit, Refine & Publish",
      description: "Broadcast your masterpiece across social media with ease",
      number: 4,
    },
  ];

  return (
    <Box sx={{ py: 20, fontFamily: "Poppins, sans-serif" }} id="process">
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          align="center"
          sx={{ mb: 6, fontSize: "3.75rem", fontWeight: 700 }}
        >
          From Words to Worlds
        </Typography>
        <Typography
          variant="h5"
          component="p"
          gutterBottom
          align="center"
          sx={{ mb: 12, fontSize: "1.25rem", color: "gray" }}
        >
          Transform your story into a visual masterpiece in four simple steps.
        </Typography>
        <Grid container spacing={8} justifyContent="center">
          {steps.map((step, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              sx={{ display: "flex" }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 8,
                  textAlign: "center",
                  borderRadius: 3,
                  boxShadow:
                    "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h6"
                  component="h5"
                  sx={{
                    mb: 2,
                    fontSize: "1.125rem",
                    fontWeight: 550,
                    color: "black",
                  }}
                >
                  {step.title}
                </Typography>
                <Box
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: "50%",
                    backgroundColor: "rgb(249, 250, 251)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 4,
                    mx: "auto",
                    fontSize: "2rem",
                    fontWeight: 900,
                    color: "#6b7280", // Match the color in the gradient
                    boxShadow:
                      "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                  }}
                >
                  <Typography
                    sx={{
                      background:
                        "linear-gradient(to bottom, #e5e7eb, #6b7280)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                      fontSize: "2rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 450,
                    }}
                  >
                    {step.number}
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  component="h3"
                  sx={{
                    mb: 2,
                    fontSize: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {step.header}
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    ontWeight: 550,
                  }}
                >
                  {step.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItWorks;
