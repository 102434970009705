// src/components/Hero.js
import React from "react";
import { Container, Typography, Grid, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import EarlyAccessWithSnackbar from "./EarlyAccess";

const Hero = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom, #fff, #F2F9FF)",
        py: 8,
        fontFamily: "Poppins, sans-serif",
      }}
      id="hero"
    >
      <Container maxWidth="xl">
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                textAlign: "center",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 700,
                fontSize: "3.75rem",
                lineHeight: 1,
                mb: 4,
              }}
            >
              Bringing Stories to Life
            </Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                paddingBottom: "56.25%", // 16:9 aspect ratio
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <iframe
                src="https://player.vimeo.com/video/992564585?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                title="Fram3.ai"
              ></iframe>
            </Box>
          </Grid>
          <script src="https://player.vimeo.com/api/player.js"></script>

          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="p"
              gutterBottom
              sx={{
                textAlign: "center",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 400,
                fontSize: "1.25rem",
                color: "text.secondary",
                mt: 4,
              }}
            >
              Turn your scripts into stunning videos in just moments
            </Typography>
          </Grid>

          <EarlyAccessWithSnackbar />

          <Grid item xs={12} md={8}>
            <Box
              sx={{
                mt: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/jc.jpeg`}
                alt="Happy Customer"
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  marginRight: "16px",
                }}
              />
              <Typography
                id="testimonial-1-quote"
                sx={{
                  mb: 2,
                  display: "inline",
                  fontSize: "1.125rem",
                  color: "text.secondary",
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faQuoteLeft}
                  style={{
                    position: "relative",
                    top: "-0.5rem",
                    marginRight: "0.25rem",
                  }}
                />
                Fram3.ai is like having your own mini-movie director in your
                pocket. My client’s are blown away by how fast i can turn ideas
                into visuals
                <FontAwesomeIcon
                  icon={faQuoteRight}
                  style={{
                    position: "relative",
                    top: "-0.5rem",
                    marginRight: "0.25rem",
                  }}
                />
                <br />
                <strong
                  style={{ fontFamily: "Poppins, sans-serif", fontWeight: 600 }}
                >
                  Ajay Singh
                </strong>
                <span
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 600,
                    color: "black",
                    paddingLeft: "0.5rem",
                  }}
                >
                  Marketing Manager
                </span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
