import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import axios from "axios";
import { isEmailValid } from "./Common/Utilities";

const EarlyAccess = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors }, setError } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://k4z848ai6i.execute-api.ap-south-1.amazonaws.com/early-access",
        { email: data.email }
      );
      if (response.status === 200) {
        enqueueSnackbar("Request for early access submitted successfully!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Subscription already exists.", {
          variant: "info",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        enqueueSnackbar("Subscription already exists.", {
          variant: "info",
        });
      } else {
        enqueueSnackbar("An error occurred. Please try again later.", {
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          fontFamily: "Poppins, sans-serif",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="flex-end"
          justifyContent="center"
        >
          <Grid item xs={12} sm={10} md={6}>
            <Box
              sx={{
                mt: 0,
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 2,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                label="Enter your email address"
                variant="outlined"
                fullWidth
                {...register("email", {
                  required: "Email is required",
                  validate: value => isEmailValid(value) || "Please enter a valid email address"
                })}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
                sx={{ fontFamily: "Poppins, sans-serif", flex: 1 }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  py: "0.375rem",
                  height: "52px",
                  borderRadius: "8px",
                  px: "1.25rem",
                  fontSize: "1.125rem",
                  fontWeight: "600",
                  color: "white",
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "#6b7280",
                  },
                  flexShrink: 0,
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Get Early Access"
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const EarlyAccessWithSnackbar = () => (
  <SnackbarProvider maxSnack={3}>
    <EarlyAccess />
  </SnackbarProvider>
);

export default EarlyAccessWithSnackbar;