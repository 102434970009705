// src/components/Header.js
import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            justifyContent: "space-between",
            fontFamily: "Poppins, sans-serif",
            padding: 0,
          }}
        >
          {/* Logo on the left */}
          <Box
            sx={{
              textAlign: { xs: "center", lg: "left" },
              flexGrow: 0,
              mr: { xs: 0, md: 0, lg: -14 },
            }}
          >
            <Link to="/" style={{ display: "inline-block" }}>
              <img
                src={`${process.env.PUBLIC_URL}/logo.png`}
                alt="Evolve Logo"
                style={{ height: "50px" }}
              />
            </Link>
          </Box>

          {/* Centered Menu Items */}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              <Button
                color="inherit"
                component={Link}
                to="/#process"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                  fontSize: "1.1rem",
                }}
              >
                Process
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/#vision"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                  fontSize: "1.1rem",
                }}
              >
                Vision
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/#features"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                  fontSize: "1.1rem",
                }}
              >
                Features
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/#pricing"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                  fontSize: "1.1rem",
                }}
              >
                Pricing
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/team"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                  fontSize: "1.1rem",
                }}
              >
                Team
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/connect"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                  fontSize: "1.1rem",
                }}
              >
                Connect
              </Button>
            </Box>
          </Box>

          {/* Menu Icon for small screens */}
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleClose}
              component={Link}
              to="/#how-it-works"
              sx={{ fontFamily: "Poppins, sans-serif" }}
            >
              How It Works
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={Link}
              to="/#vision"
              sx={{ fontFamily: "Poppins, sans-serif" }}
            >
              Vision
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={Link}
              to="/#features"
              sx={{ fontFamily: "Poppins, sans-serif" }}
            >
              Features
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={Link}
              to="/#pricing"
              sx={{ fontFamily: "Poppins, sans-serif" }}
            >
              Pricing
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={Link}
              to="/team"
              sx={{ fontFamily: "Poppins, sans-serif" }}
            >
              Team
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={Link}
              to="/connect"
              sx={{ fontFamily: "Poppins, sans-serif" }}
            >
              Connect
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={Link}
              to="/login"
              sx={{ fontFamily: "Poppins, sans-serif" }}
            >
              Login
            </MenuItem>
          </Menu>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            <Button
              component={Link}
              to="/login"
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
                fontSize: "1rem",
                color: "white",
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "#6b7280",
                },
              }}
            >
              Log In
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
