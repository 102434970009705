// config.js
export const apiConfig = {
    apiUrl: process.env.REACT_APP_API_URL,
    host: process.env.REACT_APP_API_HOST,
    path: process.env.REACT_APP_API_PATH,
    method: 'POST',
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'ap-south-1',
    service: 'execute-api'
  };
  