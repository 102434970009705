// src/components/PrivacyPolicy.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Box sx={{ py: 8, fontFamily: 'Poppins, sans-serif' }}>
      <Container maxWidth="md">
        <Typography variant="h2" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your privacy is important to us. This privacy statement explains the personal data our website processes, how our website processes it, and for what purposes.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Information We Collect
        </Typography>
        <Typography variant="body1" gutterBottom>
          We collect information to provide better services to all our users. The information we collect includes:
          <ul>
            <li>Personal Information: Name, email address, etc.</li>
            <li>Usage Data: Information on how you use our website.</li>
          </ul>
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          How We Use Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          We use the information we collect for the following purposes:
          <ul>
            <li>To provide and maintain our service</li>
            <li>To notify you about changes to our service</li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information so that we can improve our service</li>
          </ul>
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Sharing of Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          We do not share your personal information with companies, organizations, or individuals outside of our company except in the following cases:
          <ul>
            <li>With your consent</li>
            <li>For external processing</li>
            <li>For legal reasons</li>
          </ul>
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Security of Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          We work hard to protect our users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information we hold. In particular:
          <ul>
            <li>We encrypt our services using SSL</li>
            <li>We review our information collection, storage, and processing practices</li>
          </ul>
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any questions about this Privacy Policy, please contact us:
          <ul>
            <li>By email: info@fram3.ai</li>
          </ul>
        </Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
