// src/components/Vision.js
import React from "react";
import { Container, Typography, Grid, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";

const Vision = () => {
  return (
    <Box id="vision" sx={{ fontFamily: "Poppins, sans-serif", py: 12 }}>
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          component="h1"
          sx={{ textAlign: "center", fontWeight: "bold", fontSize: "3.75rem" }}
        >
          VISION
        </Typography>
        <Grid container spacing={4} sx={{ mt: 8 }}>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "24px",
                p: 6,
              }}
            >
              <img
                src="https://images.unsplash.com/photo-1478720568477-152d9b164e26?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0MzEzMzd8MHwxfHNlYXJjaHwxfHxjaW5lbWF8ZW58MHwwfHx8MTcwOTAzNjAyNHww&ixlib=rb-4.0.3&q=80&w=1920"
                alt="Hero"
                style={{ borderRadius: "24px", maxWidth: "100%" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box sx={{ pt: { xs: 8, lg: 0 }, pl: { lg: 5 }, pr: { lg: 3 } }}>
              <Typography
                variant="h3"
                component="h2"
                sx={{
                  textAlign: { xs: "center", lg: "left" },
                  fontWeight: "bold",
                  mb: 4,
                  fontSize: { xs: "2rem", lg: "3rem" },
                }}
              >
                Democratise Filmmaking
              </Typography>
              <Typography
                variant="h6"
                component="p"
                sx={{
                  textAlign: { xs: "center", lg: "left" },
                  fontSize: "1.25rem",
                  color: "text.secondary",
                  mb: 6,
                }}
              >
                Technical hurdles, high costs, and the need for specialized
                skills often leave incredible stories untold. Leveraging AI,
                Fram3 reimagine the storytelling landscape by dismantling the
                barriers of video production
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <img
                  src="https://images.unsplash.com/photo-1527612820672-5b56351f7346?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0MzEzMzd8MHwxfHNlYXJjaHwxfHxWaWRlbyUyMENyZWF0aW9uJTIwQUl8ZW58MHwwfHx8MTcwOTAzODE1N3ww&ixlib=rb-4.0.3&q=80&w=1920"
                  alt="Profile"
                  style={{
                    width: "72px",
                    height: "72px",
                    borderRadius: "50%",
                    marginRight: "16px",
                  }}
                />
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      display: "inline",
                      fontSize: "1.125rem",
                      color: "text.secondary",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faQuoteLeft}
                      style={{
                        position: "relative",
                        top: "-0.5rem",
                        marginRight: "0.25rem",
                      }}
                    />
                    Pitching ideas used to be a nightmare. But with Fram3, I can
                    whip up a stunning video in minutes
                    <FontAwesomeIcon
                      icon={faQuoteRight}
                      style={{
                        position: "relative",
                        top: "-0.5rem",
                        marginRight: "0.25rem",
                      }}
                    />
                    <br />
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 600, color: "gray.900" }}
                  >
                    Bernie D’Souza{" "}
                    <span style={{ color: "black", paddingLeft: "0.5rem" }}>
                      Freelance Video Producer
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" sx={{ py: 12 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }}>
            <Box sx={{ pr: { lg: 5 }, pt: { xs: 8, lg: 0 } }}>
              <Typography
                variant="h3"
                component="h2"
                sx={{
                  textAlign: { xs: "center", lg: "left" },
                  fontWeight: "bold",
                  mb: 4,
                  fontSize: { xs: "2rem", lg: "3rem" },
                }}
              >
                Elevate Storytelling
              </Typography>
              <Typography
                variant="h6"
                component="p"
                sx={{
                  textAlign: { xs: "center", lg: "left" },
                  fontSize: "1.25rem",
                  color: "text.secondary",
                  mb: 6,
                }}
              >
                Creating compelling stories is an art but stringent deadlines
                and resource limitations often stifle creativity which obstructs
                the path to create stand-out content. By automating the grunt
                tasks, Fram3 empowers storytellers by prioritising creativity
                and narrative richness of the story.
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <img
                  src="https://images.unsplash.com/photo-1459184070881-58235578f004?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0MzEzMzd8MHwxfHNlYXJjaHwyfHxWaWRlbyUyMENyZWF0aW9uJTIwQUl8ZW58MHwwfHx8MTcwOTAzODE1N3ww&ixlib=rb-4.0.3&q=80&w=1920"
                  alt="Profile"
                  style={{
                    width: "72px",
                    height: "72px",
                    borderRadius: "50%",
                    marginRight: "16px",
                  }}
                />
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      display: "inline",
                      fontSize: "1.125rem",
                      color: "text.secondary",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faQuoteLeft}
                      style={{
                        position: "relative",
                        top: "-0.5rem",
                        marginRight: "0.25rem",
                      }}
                    />
                    It’s a life saver! Fram3 lets me visualise crazy idea
                    without breaking the bank
                    <FontAwesomeIcon
                      icon={faQuoteRight}
                      style={{
                        position: "relative",
                        top: "-0.5rem",
                        marginRight: "0.25rem",
                      }}
                    />
                    <br />
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 600, color: "gray.900" }}
                  >
                    Akshata Menon{" "}
                    <span style={{ color: "black", paddingLeft: "0.5rem" }}>
                      Aspiring Filmmaker
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }}>
            <Box
              sx={{
                backgroundColor: "#F2F9FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "24px",
                p: 6,
              }}
            >
              <img
                src="https://images.unsplash.com/photo-1508364654111-570ff4726d27?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0MzEzMzd8MHwxfHNlYXJjaHwxN3x8Y2luZW1hdGljfGVufDB8MHx8fDE3MDkwMzU4OTF8MA&ixlib=rb-4.0.3&q=80&w=1920"
                alt="Hero"
                style={{ borderRadius: "24px", maxWidth: "100%" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" sx={{ py: 12 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                backgroundColor: "#F2F9FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "24px",
                p: 6,
              }}
            >
              <img
                src="https://images.unsplash.com/photo-1582389197101-8e56fdc81955?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0MzEzMzd8MHwxfHNlYXJjaHwxNHx8ZmlzdCUyMHJhaXNpbmd8ZW58MHwwfHx8MTcwOTAzODIyMnww&ixlib=rb-4.0.3&q=80&w=1920"
                alt="Hero"
                style={{ borderRadius: "24px", maxWidth: "100%" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box sx={{ pt: { xs: 8, lg: 0 }, pl: { lg: 5 }, pr: { lg: 3 } }}>
              <Typography
                variant="h3"
                component="h2"
                sx={{
                  textAlign: { xs: "center", lg: "left" },
                  fontWeight: "bold",
                  mb: 4,
                  fontSize: { xs: "2rem", lg: "3rem" },
                }}
              >
                A Better World
              </Typography>
              <Typography
                variant="h6"
                component="p"
                sx={{
                  textAlign: { xs: "center", lg: "left" },
                  fontSize: "1.25rem",
                  color: "text.secondary",
                  mb: 6,
                }}
              >
                Empowering storytellers can change the world by giving a voice
                to the voiceless, shaping public discourse, and fostering
                empathy and understanding. As architects of change, storytellers
                craft narratives that can raise awareness about global issues,
                challenge societal norms, and inspire individuals to take action
                for a better future.
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <img
                  src="https://images.unsplash.com/photo-1682506456442-a051e8dae813?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0MzEzMzd8MHwxfHNlYXJjaHwzfHxWaWRlbyUyMENyZWF0aW9uJTIwQUl8ZW58MHwwfHx8MTcwOTAzODE1N3ww&ixlib=rb-4.0.3&q=80&w=1920"
                  alt="Profile"
                  style={{
                    width: "72px",
                    height: "72px",
                    borderRadius: "50%",
                    marginRight: "16px",
                  }}
                />
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      display: "inline",
                      fontSize: "1.125rem",
                      color: "text.secondary",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faQuoteLeft}
                      style={{
                        position: "relative",
                        top: "-0.5rem",
                        marginRight: "0.25rem",
                      }}
                    />
                    An absolute game-changer for every storyteller. Fram3 has
                    revolutionized how I bring my scripts to life
                    <br />
                    logistical challenges.
                    <FontAwesomeIcon
                      icon={faQuoteRight}
                      style={{
                        position: "relative",
                        top: "-0.5rem",
                        marginRight: "0.25rem",
                      }}
                    />
                    <br />
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 600, color: "gray.900" }}
                  >
                    Arjun Kapoor{" "}
                    <span style={{ color: "black", paddingLeft: "0.5rem" }}>
                      Ad Film Director
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Vision;
