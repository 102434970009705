import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import { AccountCircle, Phone, Email, Description } from "@mui/icons-material";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import axios from "axios";
import { isEmailValid } from "./Common/Utilities";

const ContactForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://k4z848ai6i.execute-api.ap-south-1.amazonaws.com/Web-Email",
        data
      );
      if (response.data.message === "Email sent successfully") {
        enqueueSnackbar("Request for quote submitted successfully!", {
          variant: "success",
        });
        // Reset form fields after successful submission
        resetForm();
      } else {
        enqueueSnackbar("Failed to submit the request. Please try again.", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("An error occurred. Please try again later.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    reset();
  };

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/contact.jpg`}
            alt="Contact"
            sx={{ width: "100%", mb: 4 }}
          />
          <Typography
            variant="h4"
            component="h3"
            gutterBottom
            sx={{
              textAlign: "center",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              fontSize: "1.25rem",
            }}
          >
            <strong>Need help with your video project?</strong> Whether you have
            questions about Fram3.ai or require additional services like
            storyboarding, visualisation, or full-scale video production, we're
            here to collaborate.
          </Typography>
        </Grid>
      </Grid>
      <Container maxWidth="sm" sx={{ py: 6 }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ fontFamily: "Poppins, sans-serif" }}
        >
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item>
              <AccountCircle />
            </Grid>
            <Grid item xs>
              <TextField
                label="Name"
                name="name"
                fullWidth
                {...register("name", { required: "Name is required" })}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end" sx={{ mt: 2 }}>
            <Grid item>
              <Phone />
            </Grid>
            <Grid item xs>
              <TextField
                label="Phone Number"
                name="phone"
                fullWidth
                {...register("phone", { required: "Phone number is required" })}
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : ""}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end" sx={{ mt: 2 }}>
            <Grid item>
              <Email />
            </Grid>
            <Grid item xs>
              <TextField
                label="Email Address"
                name="email"
                fullWidth
                {...register("email", {
                  required: "Email is required",
                  validate: (value) =>
                    isEmailValid(value) || "Please enter a valid email address",
                })}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end" sx={{ mt: 2 }}>
            <Grid item>
              <Description />
            </Grid>
            <Grid item xs>
              <TextField
                label="Requirements"
                name="requirements"
                fullWidth
                multiline
                rows={4}
                {...register("requirements", {
                  required: "Requirements are required",
                })}
                error={!!errors.requirements}
                helperText={
                  errors.requirements ? errors.requirements.message : ""
                }
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 4,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{
                py: 1.5,
                fontFamily: "Poppins, sans-serif",
                backgroundColor: "black",
                "&:hover": { backgroundColor: "#6b7280" },
              }}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "primary.main",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

const ContactFormWithSnackbar = () => (
  <SnackbarProvider maxSnack={3}>
    <ContactForm />
  </SnackbarProvider>
);

export default ContactFormWithSnackbar;
