// src/components/TermsOfService.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const TermsOfService = () => {
  return (
    <Box sx={{ py: 8, fontFamily: 'Poppins, sans-serif' }}>
      <Container maxWidth="md">
        <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Fram3! These terms and conditions outline the rules and regulations for the use of Fram3's Website.
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing this website we assume you accept these terms and conditions in full. Do not continue to use Fram3's website if you do not accept all of the terms and conditions stated on this page.
        </Typography>
        <Typography variant="body1" paragraph>
          The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this website and accepting the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services/products, in accordance with and subject to, prevailing law of . Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', mt: 4 }}>
          License
        </Typography>
        <Typography variant="body1" paragraph>
          Unless otherwise stated, Fram3 and/or it’s licensors own the intellectual property rights for all material on Fram3. All intellectual property rights are reserved. You may view and/or print pages from https://www.fram3.com for your own personal use subject to restrictions set in these terms and conditions.
        </Typography>
        <Typography variant="body1" paragraph>
          You must not:
        </Typography>
        <ul>
          <li>Republish material from https://www.fram3.ai</li>
          <li>Sell, rent or sub-license material from https://www.fram3.ai</li>
          <li>Reproduce, duplicate or copy material from https://www.fram3.ai</li>
        </ul>
        <Typography variant="body1" paragraph>
          Redistribute content from Fram3 (unless content is specifically made for redistribution).
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', mt: 4 }}>
          User Comments
        </Typography>
        <Typography variant="body1" paragraph>
          This Agreement shall begin on the date hereof.
        </Typography>
        <Typography variant="body1" paragraph>
          Certain parts of this website offer the opportunity for users to post and exchange opinions, information, material and data ('Comments') in areas of the website. Fram3 does not screen, edit, publish or review Comments prior to their appearance on the website and Comments do not reflect the views or opinions of Fram3, its agents or affiliates. Comments reflect the view and opinion of the person who posts such view or opinion. To the extent permitted by applicable laws Fram3 shall not be responsible or liable for the Comments or for any loss cost, liability, damages or expenses caused and or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
        </Typography>
        <Typography variant="body1" paragraph>
          Fram3 reserves the right to monitor all Comments and to remove any Comments which it considers in its absolute discretion to be inappropriate, offensive or otherwise in breach of these Terms and Conditions.
        </Typography>
        <Typography variant="body1" paragraph>
          You warrant and represent that:
        </Typography>
        <ul>
          <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
          <li>The Comments do not infringe any intellectual property right, including without limitation copyright, patent or trademark, or other proprietary right of any third party;</li>
          <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material or material which is an invasion of privacy</li>
          <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
        </ul>
        <Typography variant="body1" paragraph>
          You hereby grant to Fram3 a non-exclusive royalty-free license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.
        </Typography>
      </Container>
    </Box>
  );
};

export default TermsOfService;
