import isEmail from "validator/lib/isEmail";

const emailoptions = {
    allow_display_name: false, // Allows an email with a display name
    require_display_name: false, // Does not require a display name
    allow_utf8_local_part: false, // Disallows non-English UTF8 characters in the local part
    require_tld: true, // Requires a Top-Level Domain in the email
    allow_ip_domain: false, // Disallows IP addresses as the domain part
    allow_underscores: false, // Disallows underscores in the domain part
    domain_specific_validation: true, // Enables domain-specific validations
    blacklisted_chars: "~!#$%^&*=", // Blacklists specific char
    host_blacklist: ["example.com"], // Blacklists 'example.com' as a host
    ignore_max_length: false, // Enforces the standard max length of an email
    host_whitelist: [], // No specific whitelist
  };

export const isEmailValid = (email) => {
    return isEmail(email, emailoptions);
  };
