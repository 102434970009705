import React, { useState, useEffect } from "react";
import axios from "axios";
import crypto from "crypto-js";
import moment from "moment";
import {
  Container,
  TextField,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";
import {
  AccountCircle,
  Lock,
  Email,
  Person,
  DateRange,
  Phone,
  Language,
  Photo,
  Home,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { isEmailValid } from "./Common/Utilities";
import { apiConfig } from "./Common/Config";
import { format } from "date-fns";
import { SnackbarProvider, useSnackbar } from "notistack";

const SignUp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    given_name: "",
    family_name: "",
    birthdate: null,
    gender: "",
    phone_number: "",
    profile: "http://example.com/profile",
    updated_at: "",
    website: "http://example.com",
    picture: "http://example.com/picture.jpg",
    zoneinfo: Intl.DateTimeFormat().resolvedOptions().timeZone,
    locale: navigator.language,
    nickname: "",
    name: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [signUpSuccess, setSignUpSuccess] = useState(false);

  useEffect(() => {
    setFormData({
      ...formData,
      name: `${formData.given_name} ${formData.family_name}`,
    });
  }, [formData.given_name, formData.family_name]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      setEmailError(!isEmailValid(value));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setFormData({ ...formData, birthdate: formattedDate });
  };

  const generateSignature = (
    method,
    host,
    path,
    body,
    accessKeyId,
    secretAccessKey,
    region,
    service
  ) => {
    const amzDate = moment().utc().format("YYYYMMDDTHHmmss") + "Z";
    const dateStamp = moment().utc().format("YYYYMMDD");

    const canonicalUri = path;
    const canonicalQueryString = "";
    const canonicalHeaders = `content-type:application/json\nhost:${host}\nx-amz-date:${amzDate}\n`;
    const signedHeaders = "content-type;host;x-amz-date";
    const payloadHash = crypto.SHA256(body).toString(crypto.enc.Hex);
    const canonicalRequest = `${method}\n${canonicalUri}\n${canonicalQueryString}\n${canonicalHeaders}\n${signedHeaders}\n${payloadHash}`;

    const algorithm = "AWS4-HMAC-SHA256";
    const credentialScope = `${dateStamp}/${region}/${service}/aws4_request`;
    const stringToSign = `${algorithm}\n${amzDate}\n${credentialScope}\n${crypto
      .SHA256(canonicalRequest)
      .toString(crypto.enc.Hex)}`;

    const kDate = crypto.HmacSHA256(dateStamp, `AWS4${secretAccessKey}`);
    const kRegion = crypto.HmacSHA256(region, kDate);
    const kService = crypto.HmacSHA256(service, kRegion);
    const kSigning = crypto.HmacSHA256("aws4_request", kService);
    const signature = crypto
      .HmacSHA256(stringToSign, kSigning)
      .toString(crypto.enc.Hex);

    const authorizationHeader = `${algorithm} Credential=${accessKeyId}/${credentialScope}, SignedHeaders=${signedHeaders}, Signature=${signature}`;

    return { authorizationHeader, amzDate };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
        apiUrl,
        host,
        path,
        method,
        accessKeyId,
        secretAccessKey,
        region,
        service,
    } = apiConfig;

    const body = JSON.stringify({
        ...formData,
        updated_at: Math.floor(Date.now() / 1000).toString(),
    });

    const { authorizationHeader, amzDate } = generateSignature(
        method,
        host,
        path,
        body,
        accessKeyId,
        secretAccessKey,
        region,
        service
    );

    const headers = {
        "Content-Type": "application/json",
        "X-Amz-Date": amzDate,
        Authorization: authorizationHeader,
    };

    try {
        const response = await axios.post(apiUrl, body, { headers });
        console.log("Response:", response.data);

        enqueueSnackbar(
            "You have successfully signed up, an email verification has been sent, please confirm the same and login.",
            { variant: "success" }
        );
        setSignUpSuccess(true);
    } catch (error) {
        let errorMessage = 'An error occurred during sign-up';
        if (error.response && error.response.data) {
            console.log("Full error response:", error.response.data);

            try {
                const outerErrorData = error.response.data;
                console.log("Outer error data:", outerErrorData);

                // Parse outer error data
                let parsedOuterErrorData;
                try {
                    parsedOuterErrorData = JSON.parse(outerErrorData);
                } catch (parseError) {
                    console.error("Failed to parse outer error data:", parseError);
                    throw parseError;
                }

                console.log("Parsed outer error data:", parsedOuterErrorData);

                if (parsedOuterErrorData.body) {
                    console.log("Outer error body:", parsedOuterErrorData.body);
                    
                    // Parse inner error data
                    let parsedInnerErrorData;
                    try {
                        parsedInnerErrorData = JSON.parse(parsedOuterErrorData.body.replace(/\\/g, ''));
                    } catch (parseError) {
                        console.error("Failed to parse inner error data:", parseError);
                        throw parseError;
                    }

                    console.log("Parsed inner error data:", parsedInnerErrorData);
                    errorMessage = `${parsedInnerErrorData.message}: ${parsedInnerErrorData.error}`;
                } else {
                    errorMessage = parsedOuterErrorData.message || error.response.data;
                }
            } catch (parseError) {
                console.error("Parse Error:", parseError);
                errorMessage = error.response.data;
            }
        } else {
            errorMessage = error.message;
        }

        enqueueSnackbar(`Sign up failed: ${errorMessage}`, { variant: "error" });
    }
};



  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4 }}>
        {signUpSuccess ? (
          <Typography variant="h6">
            You have successfully signed up, an email verification has been
            sent, please confirm the same and login.
          </Typography>
        ) : (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="username"
                  label="Username"
                  fullWidth
                  value={formData.username}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  name="password"
                  label="Password"
                  fullWidth
                  value={formData.password}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="email"
                  name="email"
                  label="Email"
                  fullWidth
                  value={formData.email}
                  onChange={handleChange}
                  error={emailError}
                  helperText={
                    emailError ? "Please enter a valid email address" : ""
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="given_name"
                  label="Given Name"
                  fullWidth
                  value={formData.given_name}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="family_name"
                  label="Family Name"
                  fullWidth
                  value={formData.family_name}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Birthdate"
                    format="dd-MMM-yyyy"
                    value={formData.birthdate}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DateRange />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="phone_number"
                  label="Phone Number"
                  fullWidth
                  value={formData.phone_number}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="profile"
                  label="Profile URL"
                  fullWidth
                  value={formData.profile}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Photo />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="website"
                  label="Website URL"
                  fullWidth
                  value={formData.website}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Home />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="picture"
                  label="Picture URL"
                  fullWidth
                  value={formData.picture}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Photo />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="zoneinfo"
                  label="Time Zone"
                  fullWidth
                  value={formData.zoneinfo}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Language />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="locale"
                  label="Locale"
                  fullWidth
                  value={formData.locale}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Language />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="nickname"
                  label="Nickname"
                  fullWidth
                  value={formData.nickname}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Box>
    </Container>
  );
};

const App = () => (
  <SnackbarProvider maxSnack={3}>
    <SignUp />
  </SnackbarProvider>
);

export default App;
