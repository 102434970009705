// src/App.js
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { scroller } from "react-scroll";
import Header from "./components/Header";
import Hero from "./components/Hero";
import HowItWorks from "./components/HowItWorks";
import Vision from "./components/Vision";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Pricing from "./components/Pricing";
import ScrollToTopButton from "./components/ScrollToTopButton";
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import ContactFormWithSnackbar from './components/ContactForm';
import { SnackbarProvider } from "notistack";
import SignUp from "./components/SignUp";
import Team from "./components/Team";

const ScrollToTop = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      scroller.scrollTo(hash.substring(1), {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [hash]);

  return null;
};

const App = () => {
  return (
    <Router>
      <SnackbarProvider maxSnack={3}>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <main>
                <Hero />
                <HowItWorks />
                <Vision />
                <Features />
                <Pricing />
              </main>
            }
          />
          <Route path="/team" element={<Team />} />
          <Route path="/connect" element={<ContactFormWithSnackbar />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/sign-up" element={<SignUp />} />
        </Routes>
        <Footer />
        <ScrollToTopButton />
      </SnackbarProvider>
    </Router>
  );
};

export default App;
