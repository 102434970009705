// src/components/Footer.js
import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  return (
    <Box
      sx={{
        py: 4,
        backgroundColor: "#f9f9f9",
        mt: 8,
        fontFamily: "Poppins, sans-serif",
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="Evolve Logo"
            style={{ height: "40px" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            mt: 2,
            mb: 4,
          }}
        >
          <Link
            component={RouterLink}
            to="/privacy-policy"
            underline="hover"
            sx={{ fontSize: "1.1rem", color: "#555" }}
          >
            Privacy Policy
          </Link>
          <Link
            component={RouterLink}
            to="/terms-of-service"
            underline="hover"
            sx={{ fontSize: "1.1rem", color: "#555" }}
          >
            Terms of Service
          </Link>
        </Box>
        <Typography
          variant="body2"
          align="center"
          gutterBottom
          sx={{ fontSize: "1rem", color: "#555" }}
        >
          © 2024 Fram3. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
