import React from "react";
import { Container, Typography, Grid, Box } from "@mui/material";
import EarlyAccessWithSnackbar from "./EarlyAccess";

const Team = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom, #fff, #F2F9FF)",
        py: 8,
        fontFamily: "Poppins, sans-serif",
      }}
      id="team"
    >
      <Container maxWidth="xl">
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                textAlign: "center",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 700,
                fontSize: "3.0rem",
                lineHeight: 1,
                mb: 4,
              }}
            >
              Unleashing a new wave of storytellers
            </Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 4,
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/team.jpg`}
                alt="Team"
                style={{
                  width: "100%",
                  maxWidth: "800px",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="p"
              gutterBottom
              sx={{
                textAlign: "center",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 300,
                fontSize: "1.5rem",
                color: "text.secondary",
                mt: 4,
              }}
            >
              We, at Fram3.ai, are a band of renegade storytellers, AI and ML
              experts on a mission: to <strong>democratise filmmaking</strong>.
            </Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            <Typography
              variant="h6"
              component="p"
              gutterBottom
              sx={{
                textAlign: "left",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
                fontSize: "1.0rem",
                color: "text.secondary",
                mt: 2,
              }}
            >
              <strong>How it’s done:</strong>
              <br />
              Our AI is a storyteller's dream. Harnessing the power of advanced
              AI/ML models, Fram3.ai brings to life a powerful script-to-video
              pipeline. Our models are meticulously built and trained on rich
              cultural and cinematic contexts, alongside a powerful large
              language model (LLM) specifically designed for cinema making.
              Fram3.ai dives deep into your script, understanding its heart and
              soul. With each word, our custom models craft a visual universe
              that's uniquely yours. From sketching characters with astonishing
              detail to animating entire worlds, our AI breathes life into your
              imagination. It's not just technology; it's artistry.
              <br />
              <br />
              Our AI isn't just a robot, it's your secret weapon. It dives deep
              into your narrative, deciphering the emotional core and
              translating it into stunning visuals that bring your stories to
              life. You focus on the narrative, we'll handle the technical
              wizardry.
              <br />
              <br />
              Join us and let's rewrite the rules of storytelling, together.
            </Typography>
          </Grid>
        </Grid>
        <EarlyAccessWithSnackbar />
      </Container>
    </Box>
  );
};

export default Team;
