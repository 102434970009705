// src/components/Features.js
import React from "react";
import { Container, Typography, Grid, Box, Paper } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilm,
  faCogs,
  faUsers,
  faShield,
  faEarthAsia,
} from "@fortawesome/free-solid-svg-icons";

const Features = () => {
  const features = [
    {
      title: "AI Director",
      description:
        "Advanced AI understands the nuances of your story to bring your vision to life",
      icon: faFilm,
    },
    {
      title: "Consistent Worlds",
      description:
        "Ensure characters and enviroments stay true to your creative vision",
      icon: faEarthAsia,
    },
    {
      title: "Personalised Content",
      description:
        "Generate content that resonates with viewers based on age, demographics and other preferences",
      icon: faCogs,
    },
    {
      title: "Collaborative Space",
      description:
        "Collaborate seamlessly with your team on projects and share feedback",
      icon: faUsers,
    },
    {
      title: "IP Protection",
      description:
        "Secure senstive and private information with end-to-end encryption",
      icon: faShield,
    },
  ];

  return (
    <Box id="features" sx={{ fontFamily: "Poppins, sans-serif", py: 6 }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            background: "linear-gradient(to bottom, #F9FAFB, #F3F4F6)",
            borderRadius: "24px",
            px: 4,
            py: 8,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            sx={{
              mb: 4,
              fontSize: { xs: "2rem", md: "3.75rem" },
              fontWeight: "bold",
            }}
          >
            Features
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{ mb: 8, fontSize: "1.25rem", color: "text.secondary" }}
          >
            Discover the innovative features that make Fram3 an essential tool
            for modern filmmaking.
          </Typography>
        </Box>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{ mt: { xs: -8, lg: -18 } }}
        >
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} lg={4} key={index}>
              <Paper
                elevation={3}
                sx={{
                  p: 6,
                  textAlign: "center",
                  borderRadius: "24px",
                  boxShadow: "0px 4px 16px rgba(59, 130, 246, 0.1)",
                }}
              >
                <Box
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: "50%",
                    backgroundColor: "rgb(249, 250, 251)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 4,
                    mt: 2,
                    mx: "auto",
                    fontSize: "2rem",
                    background: "linear-gradient(to bottom, #e5e7eb, #6b7280)",
                    color: "lightgrey", // Set the font color to black
                  }}
                >
                  <FontAwesomeIcon icon={feature.icon} />
                </Box>
                <Typography
                  variant="h4"
                  component="h3"
                  sx={{ mb: 6, fontWeight: "bold", fontSize: "1.875rem" }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: "text.secondary", fontSize: "1rem" }}
                >
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;
