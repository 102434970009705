// src/components/Pricing.js
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import EarlyAccessWithSnackbar from "./EarlyAccess";

const Pricing = () => {
  return (
    <Box id="pricing" sx={{ fontFamily: "Poppins, sans-serif", py: 6 }}>
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          component="h2"
          sx={{
            mb: 2,
            fontSize: { xs: "2rem", sm: "3rem", md: "3.75rem" },
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Pricing Plans
        </Typography>
        <Typography
          variant="h6"
          component="p"
          sx={{
            mb: { xs: 1, sm: 2 },
            fontSize: "1.25rem",
            color: "text.secondary",
            textAlign: "center",
          }}
        >
          Stay tuned for our upcoming pricing plans, designed to suit
          storytellers of all levels. Sign up now to be the first to know and
          receive exclusive early-bird offers!
        </Typography>
        <EarlyAccessWithSnackbar />
      </Container>
    </Box>
  );
};

export default Pricing;
